import dictionary from './dictionary';

export default (VeeValidate) => {
  VeeValidate.Validator.localize(dictionary);

  return {
    install(vue) {
      vue.mixin({
        mounted() {
          // For some reason VeeValidate locale is changed after mounted hook
          this.$nextTick(() => {
            if (VeeValidate.Validator.locale !== 'pt') {
              VeeValidate.Validator.localize('pt');
            }
          });
        },
      });
    },
  };
};
