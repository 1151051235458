<template>
  <div id="retriever-navigation-template">
    <v-navigation-drawer
      :value="opened"
      :color="color"
      :right="right"
      width="280"
      app
      dark
      temporary
      @input="toggleMenu($event)"
    >
      <v-list
        dense
        nav
        class="py-0"
      >
        <div v-if="menuCategories.length > 0">
          <v-list-item>
            <v-list-item-content style="margin-bottom: 10px; margin-top: 10px; color: #FFC107;">
              <v-list-item-title>
                Categorias
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="category in menuCategories"
            :key="category.title"
            style="margin-left: 20px;"
            link
            @click="goTo(category.url)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ category.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-divider />

        <v-list-item
          v-for="item in items"
          :key="item.name"
          link
          @click="goTo(item.url)"
        >
          <v-list-item-content style="color: #FFC107;">
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <div
          v-if="role === 'CUSTOMER'"
          class="seller-register"
        >
          <p> Sou um fornecedor e quero oferecer meus serviços para os pets </p>
          <v-list-item
            link
            @click="goTo('/marketplace/account/create')"
          >
            <v-list-item-content>
              <v-list-item-title>
                Cadastrar
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
const { eventBus } = window;

export default {
  name: 'RetrieverNavigationToolbar',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: () => '',
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    right: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    opened() {
      return this.isOpen;
    },
    menuCategories() {
      return this.categories.map((cat) => ({
        title: cat.title || cat.name,
        url: cat.url || `/categories/${cat.slug}`,
        ...cat,
      }));
    },
  },
  methods: {
    toggleMenu(value) {
      if (!value) {
        eventBus.$emit('toggleMenu');
      }
    },
    goTo(url) {
      this.toggleMenu(false);
      window.open(url, '_self');
    },
  },
};
</script>

<style type="text/css" scoped>
.v-list-item__content {
  margin-left: 30px;
}
.seller-register {
  margin-left: 30px;
}
.seller-register p {
  text-transform: uppercase;
  text-align: left;
}
.seller-register .v-list-item__content {
  margin-left: 0px;
  color: #ffc107;
}
</style>
