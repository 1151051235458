<template>
  <div
    id="retriever-toolbar"
    :style="customizedStyles"
  >
    <v-app-bar
      v-if="customizedStyles.backgroundColor"
      app
      dark
      :extended="showSearch"
      :color="customizedStyles.backgroundColor"
    >
      <a
        v-if="logo"
        :href="logoHref"
      >
        <v-img
          class="logo"
          max-height="48"
          min-height="48"
          contain
          :src="logo"
        />
      </a>
      <v-text-field
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="search"
        class="menu-search"
        outlined
        dense
        hide-details
        single-line
        label="Busque aqui seu serviço/produto"
        append-icon="mdi-magnify"
        required
        @click:append="goSearch(search)"
        @keyup.enter.native="goSearch(search)"
      />
      <v-spacer />
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        icon
        @click="showSearch = !showSearch"
      >
        <v-icon>{{ showSearch ? 'mdi-close' : 'mdi-magnify' }}</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="toggleMenu()"
      >
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <template
        v-if="showSearch"
        #extension
      >
        <v-text-field
          v-model="search"
          class="menu-search"
          outlined
          dense
          hide-details
          single-line
          label="Busque aqui seu serviço/produto"
          append-icon="mdi-magnify"
          required
          @click:append="goSearch(search)"
          @keyup.enter.native="goSearch(search)"
        />
      </template>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'RetrieverToolbar',
  props: {
    customizedStyles: {
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: () => '',
    },
    logo: {
      type: String,
      default: null,
    },
    logoHref: {
      type: String,
      default: () => '/',
    },
  },
  data() {
    return {
      search: '',
      showSearch: false,
    };
  },
  created() {
    if (this.customizedStyles === null) {
      this.customizedStyles.backgroundColor = '#335BDB';
    }
  },
  methods: {
    toggleMenu() {
      window.eventBus.$emit('toggleMenu');
    },
    goSearch(search) {
      window.open(`/search?term=${search}`, '_self');
    },
  },
};
</script>

<style scoped>
#retriever-toolbar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 52px;
  width: 100%;
}
</style>
