<template>
  <div
    id="retriever-item-list"
    class="container"
  >
    <v-row v-if="entries.length > 0">
      <v-col
        v-for="(item, i) in entries"
        :key="i"
        cols="12"
      >
        <v-card
          :color="item.color || 'light'"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title
              class="headline"
              v-text="item[mapTitleTo]"
            />

            <div style="text-align: left">
              <v-avatar
                style="margin-left: 20px; display: inline;"
                class="ma-3"
                size="100"
                tile
              >
                <v-img :src="item[mapImageTo]" />
              </v-avatar>
            </div>
          </div>

          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="urlToView"
              style="padding: 5px"
              text
              @click="goToView( item.id )"
            >
              Ver
            </v-btn>
            <v-btn
              v-if="urlToEdit"
              style="padding: 5px; color: blue;"
              text
              @click="goToEdit( item.id )"
            >
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <p> Nenhum {{ type }} encontrado. </p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'RetrieverItemList',
  props: {
    items: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    mapTitleTo: { type: String, default: 'title' },
    mapImageTo: { type: String, default: 'photo' },
    urlToView: { type: String, default: null },
    urlToEdit: { type: String, default: null },
    type: { type: String, default: 'Item' },
  },
  computed: {
    entries() {
      return JSON.parse(this.items);
    },

  },
  methods: {
    goToView(id) {
      window.open(`${this.urlToView}/${id}`, '_self');
    },
    goToEdit(id) {
      window.open(`${this.urlToEdit}/${id}`, '_self');
    },
  },
};
</script>

<style scoped>

</style>
