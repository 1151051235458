import createDebounceFn from './debounce';
import Localizator from './localizator';
import RetrieverToolbar from './components/toolbar/retriever-toolbar';
import RetrieverNavigationToolbar from './components/toolbar/retriever-navigation-toolbar';
import RetrieverItemList from './components/item/retriever-item-list';

const {
  Vue, Vuetify, VeeValidate, shopVue,
} = window;

Vue.component('retriever-toolbar', RetrieverToolbar);
Vue.component('retriever-navigation-toolbar', RetrieverNavigationToolbar);
Vue.component('retriever-item-list', RetrieverItemList);

Vue.use(Localizator(VeeValidate));
Vue.use(Vuetify);

function createApp(options) {
  return new Vue({
    ...options,
    vuetify: new Vuetify({
      icons: {
        iconfont: 'mdi',
      },
    }),
  });
}

window.debounce = createDebounceFn(300);

document.addEventListener('DOMContentLoaded', () => {
  // when all pushed templates were loaded
  shopVue(createApp);
});
