function capitalize([firstLetter, ...rem]) {
  return `${(firstLetter || '').toUpperCase()}${(rem || []).join('')}`;
}
export default {
  pt: {
    messages: {
      required: (field) => `Campo ${field} obrigatório`,
      alpha: (field) => `${capitalize(field)} deve conter apenas letras`,
      alpha_num: (field) => `${capitalize(field)} pode conter apenas letras e números`,
      min: (field, { length }) => `Campo ${field} deve conter pelo menos ${length} caracteres`,
      numeric: (field) => `${capitalize(field)} pode conter apenas números`,
      oneOf: (field) => `Campo ${field} deve ser um valor válido`,
      regex: (field) => `Campo ${field} inválido`,
      required_if: (field) => `Campo ${field} obrigatório`,
      size: (field, { size }) => `${capitalize(field)} deve ser menor que ${size} KB`,
      min_value: (field, { min }) => `O valor do campo ${field} deve ser maior ou igual a ${min}`,
      alpha_spaces: (field) => `${capitalize(field)} pode conter apenas letras e espaços`,
      between: (field, { min, max }) => `value ${field} deve estar entre ${min} e ${max}`,
      confirmed: (field) => `${capitalize(field)} não corresponde à confirmação`,
      digits: (field, { length }) => `${capitalize(field)} Ele deve conter apenas números e não deve exceder o ${length} number`,
      dimensions: (field, { width, height }) => `${capitalize(field)} deve ser ${width} pixels por ${height} pixels`,
      email: (field) => `${capitalize(field)} deve ser um endereço de email válido`,
      excluded: (field) => `Campo ${field} inválido`,
      ext: (field) => `Tipo M ${field} incorreto`,
      image: (field) => `${capitalize(field)} deve ser uma imagem`,
      integer: (field) => `Campo ${field} deve ser um número inteiro`,
      length: (field, { length }) => `Field ${field} não pode ter mais que ${length}`,
      max_value: (field, { min }) => `O valor do campo ${field} deve ser menor ou igual a ${min}`,
      max: (field, { length }) => `Campo ${field} deve conter no máximo ${length} caracteres`,
      mimes: (field) => `Tipo de arquivo ${field} incorreto`,
      url: (field) => `URL ${field} inválida`,
    },
  },
};
